import React from 'react';
import { graphql } from 'gatsby';
import Img from 'gatsby-image';
import Layout from '../../components/common/Layout.jsx';
import HeartDiseaseLayout from '../../components/heart-disease/HeartDiseaseLayout.jsx';

import { connect } from 'react-redux';
import { HEART_DISEASE_NAVIGATION } from '../../state/actionTypes.js';

class Signs extends React.PureComponent {
    constructor(props){
        super(props);
        props.heartDiseaseNavigation(1);
    }

    render(){
        return (
            <Layout>
                <HeartDiseaseLayout>
                    <div className="HeartDiseaseMainContainer">
                        <div className="Content">
                            <div className="SectionContainer">
                                <p className="SectionText">Dogs with heart disease may show no outward signs. With time, dogs with heart disease may develop heart failure, leading to the appearance of clinical signs that tend to get worse as the disease progresses.</p>
                                <p className="SectionText">These signs occur because of fluid build up or because the vital organs are not supplied with the blood, and therefore the oxygen, they require.</p>
                                <div className="ImageContainer">
                                    <Img
                                        fluid={this.props.data.signsTable.childImageSharp.fluid}
                                        className="TypicalImage"
                                    />
                                </div>
                                <p className="SectionTitle">Are some dogs more at risk of heart failure than others?</p>
                                <p className="SectionText">As well as older age, there are some more specific risk factors for heart failure in dogs.</p>
                                <p className="SectionTitle">Mitral valve disease is the most common cause of heart failure in small to medium breed dogs</p>
                                <p className="SectionText">In this condition, one of the heart valves (the mitral valve) becomes thickened and fails to keep an effective seal between the left atrium and left ventricle. The leaking of blood creates a sound called a 'heart murmur'. In some (but not all) cases, mitral valve disease will progress to causing heart failure.</p>
                                <p className="SectionTitle">Mitral valve disease is seen most commonly in small breed dogs, in particular:</p>
                                <ul className="SectionList">
                                    <li className="SectionListElement">&ndash; Cavalier King Charles Spaniel</li>
                                    <li className="SectionListElement">&ndash; Poodle</li>
                                    <li className="SectionListElement">&ndash; Schnauzer</li>
                                    <li className="SectionListElement">&ndash; Chihuahua</li>
                                    <li className="SectionListElement">&ndash; Fox Terrier</li>
                                    <li className="SectionListElement">&ndash; Jack Russell Terrier</li>
                                </ul>
                                <p className="SectionTitle">Dilated cardiomyopathy is a cause of heart failure most commonly diagnosed in larger dogs</p>
                                <p className="SectionText">In this condition, the heart muscle becomes too weak to pump efficiently.</p>
                                <p className="SectionTitle">Breeds at risk of dilated cardiomyopathy include:</p>
                                <ul className="SectionList">
                                    <li className="SectionListElement">&ndash; Doberman Pinscher</li>
                                    <li className="SectionListElement">&ndash; Cocker Spaniel</li>
                                    <li className="SectionListElement">&ndash; Springer Spaniel</li>
                                    <li className="SectionListElement">&ndash; Boxer</li>
                                    <li className="SectionListElement">&ndash; German Shepherd</li>
                                    <li className="SectionListElement">&ndash; Great Dane</li>
                                    <li className="SectionListElement">&ndash; St Bernard</li>
                                    <li className="SectionListElement">&ndash; Irish Wolfhound</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </HeartDiseaseLayout>
            </Layout>
        )
    }
}

export const query = graphql`{
    signsTable: file(relativePath: { eq: "heartDisease/signs.png" }){
        childImageSharp {
            fluid(maxWidth: 950){
                ...GatsbyImageSharpFluid
            }
        }
    }
}`

const mapDispatchToProps = dispatch => {
    return {
        heartDiseaseNavigation: heartDiseasePage => dispatch({
            type: HEART_DISEASE_NAVIGATION,
            heartDiseasePage,
        })
    }
}

export default connect(null, mapDispatchToProps)(Signs);
